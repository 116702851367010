import { Route, Routes, useLocation } from 'react-router-dom';
import {
  MyOrder,
  MyOrders,
  OurNotFound,
  useCommonRoutes,
  useGeneralRoutes,
  useLoginLogoutRoutes,
  usePclRoutes,
} from '@petconsole/pure-fe-feature';
import { OurEnterSite, OurExitSite, OurExternalPath, useOurRoutes } from '@petconsole/pure-fe-mui';
import { useMyShopRoutes } from '@petconsole/pure-fe-my-shops';
import { webPath } from '@petconsole/pure-shared';
import { Bus, Buses, MyBuses } from '../bus';
import { BusBuilder, BusBuilders } from '../busBuilder';
import { BusRepair, BusRepairs } from '../busRepair';
import { BusSupplies, BusSupply } from '../busSupply';
import { LandingPage } from '../landing';
import { AboutUs, Faq, Pricing, Welcome } from '../misc';

const AppRoutes = () => {
  const { pathname } = useLocation();
  const { authRoute, routeTo } = useOurRoutes();
  const commonRoutes = useCommonRoutes();
  const generalRoutes = useGeneralRoutes();
  const loginLogoutRoutes = useLoginLogoutRoutes();
  const myShopRoutes = useMyShopRoutes();
  const pclRoutes = usePclRoutes();

  return (
    <Routes>
      <>{commonRoutes.map((commonRoute) => commonRoute)}</>
      <>{generalRoutes.map((generalRoute) => generalRoute)}</>
      <>{loginLogoutRoutes.map((loginLogoutRoute) => loginLogoutRoute)}</>
      <>{pclRoutes.map((pclRoute) => pclRoute)}</>
      {routeTo(webPath.home, LandingPage)}
      {routeTo(webPath.about, AboutUs)}
      {routeTo(webPath.faq, Faq)}
      {routeTo(webPath.pricing, Pricing)}
      {routeTo(webPath.busBuilders, BusBuilders)}
      {routeTo(`${webPath.busBuilders}/:id`, BusBuilder)}
      {routeTo(webPath.busRepairs, BusRepairs)}
      {routeTo(`${webPath.busRepairs}/:id`, BusRepair)}
      {routeTo(webPath.busSupplies, BusSupplies)}
      {routeTo(`${webPath.busSupplies}/:id`, BusSupply)}
      {authRoute(webPath.buses, Buses)}
      {authRoute(webPath.myBuses, MyBuses)}
      {authRoute(`${webPath.buses}/:id`, Bus)}
      {authRoute(webPath.myOrders, MyOrders)}
      {authRoute(`${webPath.myOrders}/:id`, MyOrder)}
      <>{myShopRoutes.map((myShopRoute) => myShopRoute)}</>
      {authRoute(webPath.welcome, Welcome)}
      <Route path="/stripe-onboard/*" element={<OurExternalPath path={pathname.replace('/stripe-onboard/', '')} />} />
      {routeTo(`${webPath.site}/:site`, OurExitSite)}
      {routeTo(`${webPath.changeSite}/:id`, OurEnterSite)}
      {routeTo('*', OurNotFound)}
    </Routes>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
