import { Construction, Plumbing } from '@mui/icons-material';
import { OurBusRepairIcon } from '@petconsole/pure-fe-icon';
import { AppMenuItems } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

export const listingMenuItems = [
  [Construction, 'Bus Builders', webPath.busBuilders],
  [OurBusRepairIcon, 'Bus Repairs', webPath.busRepairs],
  [Plumbing, 'Bus Supplies', webPath.busSupplies],
] as AppMenuItems;

export const siteMedia = {
  busConsoleBanner: `${webPath.media}/landing/busconsole-banner-20220622-1.png`,
  headers: {
    builders: `${webPath.media}/headers/builders-20220903-1.png`,
    busBuilders: `${webPath.media}/headers/busBuilders-20230122-1.png`,
    buses: `${webPath.media}/headers/buses-20220906-1.png`,
    busRepairs: `${webPath.media}/headers/busRepairs-20230327-1.png`,
    busSupplies: `${webPath.media}/headers/busSupplies-20230327-1.png`,
    credits: `${webPath.media}/headers/credits-20220623-1.png`,
    logout: `${webPath.media}/headers/logout-20220623-1.png`,
    welcome: `${webPath.media}/headers/welcome-20220623-1.png`,
  },
};
