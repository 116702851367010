import { RecordType } from '@petconsole/pure-base';
import { OurLazyLoad } from '@petconsole/pure-fe-mui';

const LazyAboutUs = (props: RecordType) => <OurLazyLoad load={() => import('./AboutUs')} {...props} />;
const LazyFaq = (props: RecordType) => <OurLazyLoad load={() => import('./Faq')} {...props} />;
const LazyPricing = (props: RecordType) => <OurLazyLoad load={() => import('./Pricing')} {...props} />;
const LazyWelcome = (props: RecordType) => <OurLazyLoad load={() => import('./Welcome')} {...props} />;

export { LazyAboutUs as AboutUs };
export { LazyFaq as Faq };
export { LazyPricing as Pricing };
export { LazyWelcome as Welcome };
