import { Divider } from '@mui/material';
import {
  OurBlogMenuItem,
  OurGeneralMenu,
  OurHomeMenuItem,
  OurListingsMenu,
  OurLoginJoinMenuItems,
  OurSitesMenu,
} from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { listingMenuItems } from '../constants';

const GuestMenuItems = () => {
  return (
    <>
      <OurHomeMenuItem />
      <Divider />
      <OurBlogMenuItem />
      <Divider />
      <OurListingsMenu guest add={listingMenuItems} />
      <OurGeneralMenu guest exclude={[webPath.credits, webPath.welcome]} />
      <OurSitesMenu guest exclude={[webPath.busConsole]} />
      <Divider />
      <OurLoginJoinMenuItems />
    </>
  );
};

GuestMenuItems.whyDidYouRender = true;

export default GuestMenuItems;
