import { OurApp } from '@petconsole/pure-fe-mui';
import AppRoutes from './AppRoutes';
import GuestMenuItems from './GuestMenuItems';
import MemberMenuItems from './MemberMenuItems';
import useAvatarMenuItem from './useAvatarMenuItem';

const App = () => {
  const { avatarMenuItems } = useAvatarMenuItem();
  const appProps = { AppRoutes, GuestMenuItems, MemberMenuItems, avatarMenuItems };

  return <OurApp {...appProps} />;
};

App.whyDidYouRender = true;

export default App;
